import Vue from 'vue';
import VueRouter from 'vue-router';
import Guard from '@/services/middleware';
import LogoutMiddleware from '@/services/middleware/LogoutMiddleware';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'onepage',
    beforeEnter: Guard.unauthentic
  },

  {
    path: '/manutencao/relatorio/download/:uuid',
    name: 'relatorioManutencaoDownload',
    meta: { title: 'Relatório de Manutenções Preventivas' },
    component: () => import('@/views/condomino/outside/RelatorioManutencaoDownloadView.vue')
  },

  {
    path: '/outside/exemplary/empreendimento/:uuid',
    name: 'exemplaresEmpreendimento',
    meta: { title: 'Exemplares' },
    beforeEnter: Guard.authCustom,
    component: () => import('@/views/condomino/outside/EmpreendimentoGeralView.vue')
  },

  {
    path: '/outside/proprietario/:uuid',
    name: 'exemplaresProprietario',
    meta: { title: 'Exemplares Proprietário' },
    beforeEnter: Guard.authCustom,
    component: () => import('@/views/condomino/outside/ProprietarioView.vue')
  },

  {
    path: '/outside/sindico/:uuid',
    name: 'exemplaresSindico',
    meta: { title: 'Exemplares Síndico' },
    beforeEnter: Guard.authCustom,
    component: () => import('@/views/condomino/outside/SindicoView.vue')
  },

  {
    path: '/outside/structured-manuals/:uuid',
    name: 'manuaisEstruturados',
    meta: { title: 'Manuais Estruturados' },
    beforeEnter: Guard.authCustom,
    component: () => import('@/views/condomino/outside/ManuaisEstruturadosView.vue')
  },

  {
    path: '/redirecionamento/base2/:hashEmailSindico/:senhaDiaria',
    name: 'redirecionamentoBase2',
    meta: { title: 'Redirecionamento Base2' },
    beforeEnter: Guard.authCustom,
    component: () => import('@/views/condomino/outside/RedirecionamentoBase2View.vue')
  },

  {
    path: '/outside/qrcodes/:codigo/:uuid',
    name: 'qrCodesVideos',
    meta: { title: 'QrCode Vídeos' },
    beforeEnter: Guard.authCustom,
    component: () => import('@/views/condomino/outside/QrCodeVideosView.vue')
  },

  {
    path: '/manual/:uuid',
    name: 'loginPersonalizado',
    meta: { title: 'Autenticação' },
    beforeEnter: Guard.authCustom,
    component: () => import('@/views/autenticacao/LoginView.vue')
  },

  {
    path: '/sindico/:uuid',
    name: 'loginPersonalizadoSindico',
    meta: { title: 'Autenticação Síndico' },
    beforeEnter: Guard.authCustom,
    component: () => import('@/views/autenticacao/LoginView.vue')
  },

  {
    path: '/login',
    name: 'login',
    meta: { title: 'Autenticação' },
    component: () => import('@/views/autenticacao/LoginView.vue')
  },

  {
    path: '/esqueci-minha-senha',
    name: 'esqueceuSenha',
    meta: { title: 'Esqueci minha senha' },
    component: () => import('@/views/autenticacao/EsqueciMinhaSenhaView.vue')
  },

  {
    path: '/redefinir-senha/:uuid',
    name: 'redefinirSenha',
    meta: { title: 'Redefinir minha senha' },
    component: () => import('@/views/autenticacao/RedefinirSenhaView.vue')
  },

  {
    path: '/home',
    name: 'home',
    meta: { can: 'condomino', title: 'Home' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/home/HomeView.vue')
  },

  {
    path: '/manuais',
    name: 'manuais',
    meta: { can: 'condomino', title: 'Manuais' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/manuais/ManuaisView.vue')
  },

  {
    path: '/projetos',
    name: 'projetos',
    meta: { can: 'condomino', title: 'Projetos' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/manuais/ProjetosView.vue')
  },

  {
    path: '/databook',
    name: 'databook',
    meta: { can: 'condomino', title: 'Databook' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/databooks/IndexView.vue')
  },

  {
    path: '/databook/sumario/itens/:uuid/:nome',
    name: 'databookSumarioItens',
    meta: { can: 'condomino', title: 'Databook Sumário Itens' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/databooks/DatabookSumarioItensView.vue')
  },

  {
    path: '/localizacao',
    name: 'localizacao',
    meta: { can: 'condomino', title: 'Localização' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/localizacao/LocalizacaoView.vue')
  },

  {
    path: '/sobre',
    name: 'sobre',
    meta: { can: 'condomino', title: 'Sobre' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/sobre/SobreView.vue')
  },

  {
    path: '/videos',
    name: 'videos',
    meta: { can: 'condomino', title: 'Vídeos' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/videos/VideosView.vue')
  },

  {
    path: '/perfil',
    name: 'perfil',
    meta: { can: 'condomino', title: 'Perfil' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/perfil/PerfilView.vue')
  },

  {
    path: '/assistencia-tecnica',
    name: 'assistenciaTecnicaIndex',
    meta: { can: 'condomino', title: 'Assistência Técnica' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/assistencia-tecnica/IndexView.vue')
  },

  {
    path: '/assistencia-tecnica/novo-chamado',
    name: 'assistenciaTecnicaCreate',
    meta: { can: 'condomino', title: 'Assistência Técnica (Novo Chamado)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/assistencia-tecnica/CreateView.vue')
  },

  {
    path: '/assistencia-tecnica/detalhes/:uuid',
    name: 'assistenciaTecnicaShow',
    meta: { can: 'condomino', title: 'Assistência Técnica (Detalhes Chamado)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/assistencia-tecnica/ShowView.vue')
  },

  {
    path: '/assistencia-tecnica/atualizar/:uuid',
    name: 'assistenciaTecnicaUpdate',
    meta: { can: 'condomino', title: 'Assistência Técnica (Atualizar Chamado)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/assistencia-tecnica/UpdateView.vue')
  },

  {
    path: '/assistencia-tecnica/avaliacao/:uuid',
    name: 'assistenciaTecnicaAvaliacao',
    meta: { can: 'condomino', title: 'Assistência Técnica (Avaliação)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/assistencia-tecnica/AvaliacaoView.vue')
  },

  {
    path: '/assistencia-tecnica/gestao/painel',
    name: 'assistenciaTecnicaGestaoPainel',
    meta: { can: 'condomino', title: 'Gestão de Assistência Técnica' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/gerenciamento-chamados/IndexView.vue')
  },

  {
    path: '/assistencia-tecnica/gestao/detalhes/:uuid',
    name: 'assistenciaTecnicaGestaoShow',
    meta: { can: 'condomino', title: 'Gestão de Assistência Técnica (Detalhes Chamado)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/gerenciamento-chamados/ShowView.vue')
  },

  {
    path: '/assistencia-tecnica/gestao/atualizar/:uuid',
    name: 'assistenciaTecnicaGestaoUpdate',
    meta: { can: 'condomino', title: 'Gestão de Assistência Técnica (Atualizar Chamado)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/gerenciamento-chamados/UpdateView.vue')
  },

  {
    path: '/assistencia-tecnica/gestao/atendimento/:uuid',
    name: 'assistenciaTecnicaGestaoAtendimentoIndex',
    meta: { can: 'condomino', title: 'Gestão de Assistência Técnica (Atendimento)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/gerenciamento-chamados/atendimento/IndexView.vue')
  },

  {
    path: '/assistencia-tecnica/gestao/assistencia/:uuid',
    name: 'assistenciaTecnicaGestaoAssistenciaIndex',
    meta: { can: 'condomino', title: 'Gestão de Assistência Técnica (Assistência)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/gerenciamento-chamados/assistencia/IndexView.vue')
  },

  {
    path: '/manutencoes-preventivas',
    name: 'manutencaoPreventivaIndex',
    meta: { can: 'condomino', title: 'Manutenção Preventiva (Dashboard)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/manutencoes-preventivas/dashboard/DashboardView.vue')
  },

  {
    path: '/manutencoes-preventivas/:uuid',
    name: 'manutencaoPreventivaShow',
    meta: { can: 'condomino', title: 'Manutenção Preventiva' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/manutencoes-preventivas/IndexView.vue')
  },

  {
    path: '/manutencoes-preventivas/:uuid/fornecedor/detalhes/:id',
    name: 'manutencaoPreventivaFornecedorShow',
    meta: { can: 'condomino', title: 'Manutenção Preventiva (Detalhes do Fornecedor)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/manutencoes-preventivas/fornecedores/showView.vue')
  },

  {
    path: '/manutencoes-preventivas/:uuid/fornecedor/novo-fornecedor',
    name: 'manutencaoPreventivaFornecedorCreate',
    meta: { can: 'condomino', title: 'Manutenção Preventiva (Novo Fornecedor)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/manutencoes-preventivas/fornecedores/createView.vue')
  },

  {
    path: '/manutencoes-preventivas/:uuid/fornecedor/editar-fornecedor/:id',
    name: 'manutencaoPreventivaFornecedorEdit',
    meta: { can: 'condomino', title: 'Manutenção Preventiva (Editar Fornecedor)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/condomino/manutencoes-preventivas/fornecedores/editView.vue')
  },

  {
    path: '/construtora/home',
    name: 'construtoraHome',
    meta: { can: 'construtora', title: 'Home' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/home/IndexView.vue')
  },

  {
    path: '/construtora/manuais',
    name: 'construtoraManuais',
    meta: { can: 'construtora', title: 'Manuais' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/manuais/IndexView.vue')
  },

  {
    path: '/construtora/databooks/:empreendimento?',
    name: 'construtoraDatabooks',
    meta: { can: 'construtora', title: 'Construtora Databooks' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/databook/IndexView.vue')
  },

  {
    path: '/construtora/empreendimentos',
    name: 'construtoraEmpreendimentos',
    meta: { can: 'construtora', title: 'Empreendimentos' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/empreendimentos/IndexView.vue')
  },

  {
    path: '/construtora/manuais/empreendimento/:uuid',
    name: 'construtoraManuaisEmpreendimento',
    meta: { can: 'construtora', title: 'Empreendimento (Manuais)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/manuais/ManuaisEmpreendimentoView.vue')
  },

  {
    path: '/construtora/videos/empreendimento/:uuid',
    name: 'construtoraVideosEmpreendimento',
    meta: { can: 'construtora', title: 'Empreendimento (Vídeos)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/videos/VideosEmpreendimentoView.vue')
  },

  {
    path: '/construtora/databook/empreendimento/:id/:uuid',
    name: 'construtoraDatabookEmpreendimento',
    meta: { can: 'construtora', title: 'Empreendimento (Databook)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/databook/DatabookEmpreendimentoView.vue')
  },

  {
    path: '/construtora/databook/sumario/itens/:uuid/:nome',
    name: 'construtoraDatabookSumarioItens',
    meta: { can: 'construtora', title: 'Databook Sumário Itens' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/databook/DatabookSumarioItensView.vue')
  },

  {
    path: '/construtora/condominos',
    name: 'construtoraCondominos',
    meta: { can: 'construtora', title: 'Empreendimento (Condominos)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/condominos/IndexView.vue')
  },

  {
    path: '/construtora/downloads',
    name: 'construtoraDownloadsManuais',
    meta: { can: 'construtora', title: 'Empreendimento (Downloads Manuais)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/manuais/DownloadsManuaisView.vue')
  },

  {
    path: '/construtora/manutencoes-preventivas/relatorio',
    name: 'construtoraManutencoesRelatorio',
    meta: { can: 'construtora', title: 'Manutenções Preventivas (Relatório)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/manutencoes/RelatorioView.vue')
  },

  {
    path: '/construtora/manutencoes',
    name: 'construtoraManutencoes',
    meta: { can: 'construtora', title: 'Manutenções Preventivas (Dashboard)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/manutencoes/DashboardView.vue')
  },

  {
    path: '/construtora/manutencao/:uuid',
    name: 'construtoraManutencaoPreventivaShow',
    meta: { can: 'construtora', title: 'Manutenções Preventivas' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/manutencoes/IndexView.vue')
  },

  {
    path: '/construtora/manutencoes-preventivas/fornecedor/detalhes/:id/:uuid',
    name: 'construtoraManutencaoPreventivaFornecedorShow',
    meta: { can: 'construtora', title: 'Manutenções Preventivas (Detalhes do Fornecedor)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/manutencoes/ShowFornecedorView.vue')
  },

  {
    path: '/construtora/assistencia-tecnica',
    name: 'construtoraAssistenciaTecnica',
    meta: { can: 'construtora', title: 'Assistência Técnica' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/assistencia-tecnica/IndexView.vue')
  },

  {
    path: '/construtora/assistencia-tecnica/gestao/detalhes/:uuid',
    name: 'construtoraAssistenciaTecnicaGestaoShow',
    meta: { can: 'construtora', title: 'Assistência Técnica (Detalhes)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/assistencia-tecnica/ShowView.vue')
  },

  {
    path: '/construtora/assistencia-tecnica/gestao/atualizar/:uuid',
    name: 'construtoraAssistenciaTecnicaGestaoUpdate',
    meta: { can: 'construtora', title: 'Assistência Técnica (Atualizar)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/assistencia-tecnica/UpdateView.vue')
  },

  {
    path: '/construtora/assistencia-tecnica/gestao/atendimento/:uuid',
    name: 'construtoraAssistenciaTecnicaGestaoAtendimentoIndex',
    meta: { can: 'construtora', title: 'Assistência Técnica (Atendimento)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/assistencia-tecnica/atendimento/IndexView.vue')
  },

  {
    path: '/construtora/assistencia-tecnica/gestao/assistencia/:uuid',
    name: 'construtoraAssistenciaTecnicaGestaoAssistenciaIndex',
    meta: { can: 'construtora', title: 'Assistência Técnica (Assistência)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/assistencia-tecnica/assistencia/IndexView.vue')
  },

  {
    path: '/construtora/assistencia-tecnica/criar',
    name: 'construtoraAssistenciaTecnicaCreate',
    meta: { can: 'construtora', title: 'Assistência Técnica (Novo Chamado)' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/assistencia-tecnica/CreateView.vue')
  },

  {
    path: '/construtora/relatorios',
    name: 'construtoraRelatoriosIndex',
    meta: { can: 'construtora', title: 'Construtora Relatórios' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/relatorios/IndexView.vue')
  },

  {
    path: '/construtora/categorias',
    name: 'construtoraCategoriasIndex',
    meta: { can: 'construtora', title: 'Categorias' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/categorias/IndexView.vue')
  },

  {
    path: '/construtora/base-de-solucoes',
    name: 'construtoraBaseSolucoesIndex',
    meta: { can: 'construtora', title: 'Base de Soluções' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/base-solucoes/IndexView.vue')
  },

  {
    path: '/construtora/configuracoes',
    name: 'construtoraConfiguracoesIndex',
    meta: { can: 'construtora', title: 'Configurações' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/configuracoes/IndexView.vue')
  },

  {
    path: '/construtora/perfil',
    name: 'construtoraPerfil',
    meta: { can: 'construtora', title: 'Construtora Perfil' },
    beforeEnter: Guard.auth,
    component: () => import('@/views/construtora/perfil/IndexView.vue')
  },

  {
    path: '/logout',
    name: 'logout',
    beforeEnter: LogoutMiddleware.logout,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active-item-sidebar'
});

router.beforeEach((to, from, next) => {
  document.title = ['', undefined, false, null].includes(to?.meta?.title) ? 'Guiare SG2 Sistemas' :  `Guiare SG2 Sistemas - ${to.meta.title}`;
  next();
});

export default router;
